import { createSlice } from '@reduxjs/toolkit';

interface IScenario {
  numberOfSupplierResponses: {
    operator: string | null;
    value: string | null;
  };
  maxAllocation: string | null;
  groupBasedIn: string | null;
  isGroupByPlant:Boolean |null;
  supplierRating: number | null;
  leadTime: string | null;
  esgRating: string | null;
}

export interface IScenarioData {
  columnData: any[];
  rowData: any[];
}
export interface IScenarioDataList {
  partDetails: IScenarioData;
  awardPlannerData: IScenarioData;
  basicAwardPlannerData: IScenarioData;
  _id: string;
  scenarioName: string;
  scenarioCondition: IScenario;
}

export interface IAwardPlannerData {
  scenarioId: string | null;
  scenarioName: string | null;
  data: IScenarioData | null;
}
interface IScenarioConditionState {
  scenarioCondition: IScenario;
  scenarioList: IScenarioDataList[] | null;
  selectedScenarioId: string | null;
  selectedScenarioName: string | null;
  selectedScenarioData: IAwardPlannerData | null;
}

const initialState: IScenarioConditionState = {
  scenarioCondition: {
    numberOfSupplierResponses: {
      operator: null,
      value: null,
    },
    maxAllocation: null,
    groupBasedIn: null,
    isGroupByPlant:false,
    supplierRating: null,
    leadTime: null,
    esgRating: null,
  },
  scenarioList: null,
  selectedScenarioId: null,
  selectedScenarioName: null,
  selectedScenarioData: null,
};

const approverSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
    updateScenarioConditionState(state, action) {
      state.scenarioCondition = {
        ...state.scenarioCondition,
        ...action.payload,
      };
    },

    setScenarioData(state, action) {
      const scenarioData: any = action.payload;
      console.log('scenarioData', scenarioData);
    },

    resetNewSenarioData(state) {
      state.selectedScenarioId = null;
      state.selectedScenarioName = null;
    },

    resetScenarioConditionState(state) {
      //Commented the below code as it was resetting the selectedScenarioId and selectedScenarioName
      // state.selectedScenarioId = null;
      // state.selectedScenarioName = null;
      state.scenarioCondition = {
        numberOfSupplierResponses: {
          operator: null,
          value: '',
        },
        maxAllocation: '',
        groupBasedIn: null,
        isGroupByPlant:false,
        supplierRating: null,
        leadTime: '',
        esgRating: '',
      };
    },
    setScenarioList(state, action) {
      state.scenarioList = action.payload;
    },
    setSelectedScenarioIdAndName(state, action) {
      state.selectedScenarioId = action.payload.scenarioId;
       state.selectedScenarioName = action.payload.scenarioName;
    },
    setSelectedScenarioData(state, action) {
      state.selectedScenarioData = action.payload;
    },
    setEditScenarioData(state, action) {
      const { scenarioId, scenarioName, scenarioCondition } = action.payload;
      state.selectedScenarioId = scenarioId;
      state.selectedScenarioName = scenarioName;
      state.scenarioCondition = scenarioCondition;
    },
    resetScenarioList(state) {
      state.scenarioList = null;
    },
    resetSelectedScenarioData(state, action) {
      state.selectedScenarioData = null;
      state.selectedScenarioId = null;
    }
  },
});

export const {
  updateScenarioConditionState,
  resetScenarioConditionState,
  setScenarioList,
  resetScenarioList,
  setScenarioData,
  setSelectedScenarioIdAndName,
  setSelectedScenarioData,
  resetSelectedScenarioData,
  setEditScenarioData,
  resetNewSenarioData
} = approverSlice.actions;
export default approverSlice.reducer;
