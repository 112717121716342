import {
  BASE_URL,
  SPEND_CUBE_BASE_URL,
  VERSION,
  LOGIN,
  SIGN_UP,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  GET_USERS,
  FET_SUPPLIER_RFI,
  INVITE_USERS,
  UPDATE_INVITED_USER,
  GENERATE_PROJECT_ID,
  CHANGE_PASSWORD,
  VERIFY_USER,
  ORGANISATION_PROFILE_DETAILS,
  VERIFY_BUISNESS_EMAIL,
  CREATE_PASSWORD,
  GET_INVITED_USER_DETAILS,
  VERIFY_REFRESH_TOKEN,
  REINVITE_USER,
  RESEND_VERIFICATION_LINK,
  GET_ALL_PROJECTS,
  GET_TEAM_MEMBERS,
  SUPPLIER_PROJECTS,
  CREATE_PROJECT,
  GET_RFI_TEMPLATES,
  GET_TEMPLATE_BY_ID,
  CREATE_RFI,
  GET_SUPPLIERS,
  GET_SUPPLIER,
  INVITE_SUPPLIERS,
  LAUNCH_RFI,
  GET_PROJECT_DETAILS,
  UPDATE_RFI,
  SELLER_SIGNUP,
  GET_RFI_RESPONSES,
  DELETE_PROJECT,
  NOTIFICATION_USER,
  NOTIFICATION_POOL,
  NOTIFICATION_READ_ALL,
  NOTIFICATION_READ_SINGLE,
  GET_SINGLE_SUPPLIER,
  GET_SUPPLIER_PROJECT_DETAILS,
  UPDATE_SUPPLIER_PROJECT,
  SAVE_SUPPLIER_RESPONSES,
  ACCEPT_OR_REJECT_PROJECT,
  NOTIFICATION_UNREAD_SINGLE,
  AUTOFILL_ENDPOINT,
  GET_SHORTLISTED_SUPPLIERS,
  SHORTLIST_SUPPLIERS,
  UNSHORTLIST_SUPPLIERS,
  GET_SUPPLIER_RESPONSES,
  PROCEED_TO_RFQ,
  ADD_SUPPLIERS_TO_RFI,
  CONVERSATION_BADGE_GLOBEL_COUNTS,
  GET_PROJECT_ROLE_DROPDOWN_DATA,
  SAVE_APPROVAL_MATRIX,
  GET_APPROVAL_MATRIX_DATA,
  UPDATE_APPROVAL_MATRIX,
  REQUEST_FOR_APPROVAL,
  GET_APPROVAL_STATUS,
  PROJECT_APPROVAL,
  GET_APPROVAL_INBOX_DETAILS,
  INVITE_NEW_USERS_TO_PROJECTS,
  GEt_VERSION_DETAILS
} from "./endpoints";
import HttpClient from "./HttpClient";

export const loginOperation = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + LOGIN;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const signUpOperation = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + SIGN_UP;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const forgotPasswordOperation = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + FORGET_PASSWORD;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const changePassword = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL =
        `${BASE_URL}${VERSION}${CHANGE_PASSWORD}` +
        "/" +
        `${localStorage.getItem("userId")}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const resetPassword = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = ` ${BASE_URL}${VERSION}${RESET_PASSWORD}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const createPassword = (payload: object, id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = ` ${BASE_URL}${VERSION}${CREATE_PASSWORD}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUsers = (id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_USERS}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const inviteUsers = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + INVITE_USERS;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getInvitedUser = (id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_INVITED_USER_DETAILS}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const reInviteUser = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${REINVITE_USER}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateInvitedUsers = (payload: object, id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${UPDATE_INVITED_USER}${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const generateProjectId = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${GENERATE_PROJECT_ID}/${userId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyUserOperation = (id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${VERIFY_USER}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getOrganisationDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${ORGANISATION_PROFILE_DETAILS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveOrganisationDetails = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${ORGANISATION_PROFILE_DETAILS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUsersProfile = (payload: object, userId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + "user/" + userId;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.put(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyBuisnessEmail = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + VERIFY_BUISNESS_EMAIL;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyRefreshToken = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const id = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${VERIFY_REFRESH_TOKEN}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const resendVerificationLink = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const id = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${RESEND_VERIFICATION_LINK}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllProject = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${GET_ALL_PROJECTS}/${userId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
export const getConversationNotification = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${CONVERSATION_BADGE_GLOBEL_COUNTS}${userId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("rretertert", URL);
      const response = await HttpClient.get(URL, CONFIG);
      console.log(response);

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSupplierProjects = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${GET_ALL_PROJECTS}/${SUPPLIER_PROJECTS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTeamMembers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${GET_TEAM_MEMBERS}/${userId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const createProject = (projectId: any, payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${CREATE_PROJECT}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRFITemplates = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_RFI_TEMPLATES}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSelectedRFITemplates = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const templateId = localStorage.getItem("templateId");
      const preferedLanguage = localStorage.getItem("selectedLanguage");
      const URL = `${BASE_URL}${VERSION}${GET_TEMPLATE_BY_ID}/${templateId}/${preferedLanguage}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const createRFITemplate = (payload: any, projectId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${CREATE_RFI}/${projectId}/${userId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSuppliers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const templateId = localStorage.getItem("templateId");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIERS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

// V1 API for getting supplier Lists
export const getSupplier = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const templateId = localStorage.getItem("templateId");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIER}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

// V2 API for getting supplier Lists
export const getBuyerSupplier = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const templateId = localStorage.getItem("templateId");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIER}/buyerSuppliers`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

// V3 API for getting supplier Lists
export const getRfqBuyerSupplierList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const templateId = localStorage.getItem("templateId");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIER}/buyerSupplier/inviteList`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
export const getSingleSupplier = (id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_SINGLE_SUPPLIER}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSingleSupplierV2 = (id: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_SINGLE_SUPPLIER}/supplierDetails/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const inviteSuppliers = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${INVITE_SUPPLIERS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const launchRFI = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const rfiID = localStorage.getItem("rfiId");
      const URL = `${BASE_URL}${VERSION}${LAUNCH_RFI}/${rfiID}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProjectDetaisById = (projectId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_PROJECT_DETAILS}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateRFITemplate = (payload: any, rfiID: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = localStorage.getItem("userId");
      const URL = `${BASE_URL}${VERSION}${UPDATE_RFI}/${rfiID}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const sellerSignUpOperation = (payload: object, id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${SELLER_SIGNUP}/${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRFIResponsesById = (rfiId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_RFI_RESPONSES}/${rfiId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteProject = (projectId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${DELETE_PROJECT}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSupplierRfi = (rfiId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preferredLanguage = localStorage.getItem("selectedLanguage") || "en";
      const URL = `${BASE_URL}${VERSION}${FET_SUPPLIER_RFI}/${rfiId}/${preferredLanguage}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSupplierProjectDetailsById = (projectId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preferedLanguage = localStorage.getItem("selectedLanguage");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIER_PROJECT_DETAILS}/${projectId}/${preferedLanguage}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllNotification = (pageNumber: number) => {
  return new Promise(async (resolve, reject) => {
    const id = localStorage.getItem("userId");
    try {
      const URL = `${BASE_URL}${VERSION}${NOTIFICATION_USER}${id}?offset=${pageNumber}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const markAsReadAllNotification = () => {
  return new Promise(async (resolve, reject) => {
    const id = localStorage.getItem("userId");
    try {
      const URL = `${BASE_URL}${VERSION}${NOTIFICATION_READ_ALL}${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const markSingleNotificationAsRead = (notificationId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${NOTIFICATION_READ_SINGLE}${notificationId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
export const markSingleNotificationAsUnRead = (notificationId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${NOTIFICATION_UNREAD_SINGLE}${notificationId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const notificationPooling = () => {
  return new Promise(async (resolve, reject) => {
    const id = localStorage.getItem("userId");
    try {
      const URL = `${BASE_URL}${VERSION}${NOTIFICATION_POOL}${id}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateSupplierProject = (projectId: string, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${UPDATE_SUPPLIER_PROJECT}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveSupplierResponses = (projectId: string, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${SAVE_SUPPLIER_RESPONSES}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const acceptRejectProject = (projectId: string, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${ACCEPT_OR_REJECT_PROJECT}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAutofillData = (type: string) => {
  return new Promise(async (resolve, reject) => {
    const preferredLanguage = localStorage.getItem("selectedLanguage");
    const id = localStorage.getItem("userId");
    try {
      const URL = `${BASE_URL}${VERSION}${AUTOFILL_ENDPOINT}/${type}/${preferredLanguage}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getShortlistedSuppliers = (rfiId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_SHORTLISTED_SUPPLIERS}/${rfiId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const shortlistSuppliers = (projectId: any, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${SHORTLIST_SUPPLIERS}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const unShortlistSuppliers = (projectId: any, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${UNSHORTLIST_SUPPLIERS}/${projectId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSupplierResponses = (projectId: any, tenantId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const prefferedLanguage = localStorage.getItem("selectedLanguage");
      const URL = `${BASE_URL}${VERSION}${GET_SUPPLIER_RESPONSES}/${projectId}/${tenantId}/${prefferedLanguage}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const proceedToRfq = (rfiId: any, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${PROCEED_TO_RFQ}/${rfiId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const addSuppliersToRfi = (rfiId: any, payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${ADD_SUPPLIERS_TO_RFI}/${rfiId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProjectDropDownData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_PROJECT_ROLE_DROPDOWN_DATA}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveApprovalMatrixData = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${SAVE_APPROVAL_MATRIX}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateApprovalMatrixData = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${UPDATE_APPROVAL_MATRIX}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getApprovalMatrixData = (projectId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_APPROVAL_MATRIX_DATA}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          projectId: projectId,
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const requestForApproval = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${REQUEST_FOR_APPROVAL}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getApprovalStatus = (projectId: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = `?projectId=${projectId}`;
      const URL = `${BASE_URL}${VERSION}${GET_APPROVAL_STATUS}/logs${params}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const approveOrRejectProject = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${PROJECT_APPROVAL}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const UploadFilesInAttachment = (
  formData: FormData | null,
  projectId: string | null,
  selectedFolderData: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const folderName =
        selectedFolderData?.folderName !== undefined
          ? selectedFolderData.folderName
          : '""';
      const parentFolder =
        selectedFolderData?.parentFolder !== undefined
          ? selectedFolderData.parentFolder
          : '""';
      const folderUrl =
        selectedFolderData?.folderUrl !== undefined
          ? selectedFolderData.folderUrl
          : '""';
      const filePermission =
        selectedFolderData?.filePermission !== undefined
          ? selectedFolderData.filePermission
          : "66c300ff8c99e6e93e8f13c1";

      const params = `?projectId=${projectId}&folderName=${folderName}&parentFolder=${parentFolder}&filePermission=${filePermission}&folderUrl=${folderUrl}`;
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/upload${params}`;
      const CONFIG = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await HttpClient.post(URL, { file: formData }, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUploadedFilesData = (projectId: string | null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = `?projectId=${projectId}`;
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/download${params}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
export const getUploadedFilesDatabySuppliersId = (
  projectId: string | null,
  supplierId: string | null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = `?projectId=${projectId}&supplierId=${supplierId}`;
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/download${params}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const UpdateFileName = (
  id: string | null,
  fileName: string | null,
  description: string | null
) => {
  return new Promise(async (resolve, reject) => {
    const payload = {
      id,
      fileName,
      description,
    };
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/update`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteCurrentFile = (
  fileId: string | null,
  projectId: string | null,
  fileName: string
) => {
  return new Promise(async (resolve, reject) => {
    const payload = {
      id: fileId,
      projectId,
      fileName,
    };
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/delete`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const createNewFolder = (
  projectId: string | null,
  folderName: string | null,
  parentFolder: string | null,
  folderUrl: string | null,
  filePermission: string | null
) => {
  return new Promise(async (resolve, reject) => {
    const payload = {
      projectId,
      folderName,
      parentFolder,
      folderUrl,
      filePermission,
    };
    try {
      const URL = `${process.env.REACT_APP_BASE_URL}/v1/project/attachment/createFolder`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getApprovalInboxdetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_APPROVAL_INBOX_DETAILS}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("url", URL);

      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

// V2 api for inviting the suppliers.
export const inviteNewUsersToProjects = (payload: object) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + INVITE_NEW_USERS_TO_PROJECTS;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.post(URL, payload, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSingleSupplierFromMarketPlace = (supplierId: string | null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = `${BASE_URL}${VERSION}${GET_SINGLE_SUPPLIER}/supplierDetailsFromMarketPlace/${supplierId}`;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

//version Details API
export const getVersionDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const URL = BASE_URL + VERSION + GEt_VERSION_DETAILS;
      const CONFIG = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await HttpClient.get(URL, CONFIG);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
