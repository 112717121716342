import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { verifyRefreshToken } from './apiHandler';

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('tenantId') || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.tenant = tenantId;
    }
    return config;
  },
  (error) => Promise.reject(error)
);



HttpClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  async (error) => {

    // console.log(error.response.status)
    const { config: { url, method, headers, data } } = error;
    const payload = {
      refreshToken: localStorage.getItem('refreshToken')
    }

    const shouldHitRequest = (localStorage.getItem('rememberMe') === "true") && (error.response.status === 403) ? true : false;
    console.log(shouldHitRequest)
    if (shouldHitRequest) {

      return await verifyRefreshToken(payload).then(async (response: any) => {

        if (response?.data?.success) {
          console.log(response.data.data.existingUser)
          localStorage.setItem(
            'loginUserVerified',
            response.data.data.data.userVerified
          );
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem('userVerified', response?.data?.data?.data?.userVerified);
          localStorage.setItem('email', response?.data?.data?.data?.email);
          localStorage.setItem('userId', response?.data?.data?.data?.userId);
          localStorage.setItem('accessToken', response?.data?.data?.data?.accessToken);
          localStorage.setItem('firstName', response?.data?.data?.existingUser?.firstName);
          localStorage.setItem('lastName', response?.data?.data?.existingUser?.lastName);
          localStorage.setItem('tenantId', response?.data?.data?.existingUser?.tenantId);
          localStorage.setItem('timeZone', response?.data?.data?.existingUser?.preferedTimeZone);
          localStorage.setItem('refreshToken', response?.data?.data?.data?.refreshToken);
          localStorage.setItem('rememberMe', response?.data?.data?.existingUser?.rememberMe);
          localStorage.setItem('permissions', JSON.stringify(response?.data?.data?.existingUser?.permissions));
          localStorage.setItem('phone', response?.data?.data?.data?.phone);


          return await recallApi(url, method, headers, data).then((response: any) => {
            console.log("in then block of verify function")
            console.log(response)
            return Promise.resolve(response)
          }).catch((error: any) => {
            console.log(error, "hellocwckw")
            return Promise.reject(error)
          })
        }
        else {
          console.log("wee;cadda else confotoj")
          return Promise.resolve(response)

        }
      }).catch((error) => {

        return Promise.reject(error)
      })
    }
    else
      return Promise.reject(error)

  }
);



const recallApi = (url: string, method: string, headers: object, data: object) => {
  if (method === 'get') {
    return new Promise(async (resolve, reject) => {
      try {
        const URL = url;
        const CONFIG = headers
        const response = await HttpClient.get(URL, CONFIG);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  else if (method === 'post') {
    return new Promise(async (resolve, reject) => {
      try {
        const URL = url;
        const CONFIG = headers
        const response = await HttpClient.post(URL, data, CONFIG);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  else {
    return new Promise(async (resolve, reject) => {
      try {
        const URL = url;
        const CONFIG = headers
        const response = await HttpClient.put(URL, data, CONFIG);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }


}





export default HttpClient;
